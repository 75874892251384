import { RewardType, GameType, Reward } from 'ui/types/Games'
import { Assignment } from 'ui/types/GameTypes'

import { GameResponse, Rules, StatusType } from '../../types'

interface NormalizedGame {
  id: number
  assign: Assignment
  name: string
  endDateTime: Date
  coverImageUrl: string | null
  startDateTime: Date
  gameType: GameType
  goal: number
  metric: string
  place: number
  unit: string
  rewards: Array<{
    type: RewardType
    value: any
  }>
  rules: Rules
  prizeAmount?: number
  prizeType: RewardType
  status: StatusType
  totalScore: number
  userId?: number
  userName?: string
  userImageUrl?: string
  isRewardImmediatelyDistributed?: boolean
  isSingleGoalReward?: boolean
  earnedReward?: Reward
  awardCount?: number
  limitWinners?: number
  numberOfWinners?: number
}

export const normalizeGame = (game: GameResponse): NormalizedGame => {
  return {
    id: game.id,
    assign: game.assign,
    name: game.name,
    endDateTime: game.expiresAt,
    coverImageUrl: game?.coverImageUrl || null,
    startDateTime: game.startsAt,
    gameType: game.type,
    goal: game.rules.goal,
    metric: game.rules.metric,
    place: game.playerInformation.ranking,
    unit: game.rules.unit,
    rewards: game.rewards,
    rules: game.rules,
    prizeAmount: parseInt(game.rewards[0].value),
    prizeType: game.rewards[0]?.type,
    status: game.status,
    totalScore: game.playerInformation.score,
    userId: game.winner?.id,
    userName: game.winner?.name,
    userImageUrl: game.winner?.imageUrl,
    isRewardImmediatelyDistributed: game.isRewardImmediatelyDistributed,
    isSingleGoalReward: game.isSingleGoalReward,
    earnedReward: game.playerInformation.earnedReward,
    awardCount: game.playerInformation.awardCount,
    limitWinners: game.limitWinners,
    numberOfWinners: game.numberOfWinners,
  }
}
