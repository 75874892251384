import { FC, SyntheticEvent, useContext } from 'react'
import { Box, Flex, Heading } from '@chakra-ui/react'
import { CbColorBanner } from 'ui/components/CbColorBanner'
import { CbMobileFilterDateRange } from 'ui/components/CbMobileFilterDateRange'
import { CbSelect } from 'ui/components/CbSelect'
import {
  CbGameCardOld,
  CbGameCardProps,
  CbGameCardSkeletonOld,
} from 'ui/components/CbGameCard'
import { CbTablePagination } from 'ui/components/CbTable/CbTablePagination'
import { upFirst } from 'ui/helpers'
import { GamesEmptyState } from '../GamesEmptyState'
import { AuthContext, MPThemeContext } from '../../providers'
import { Feature, gamesFilterOptionValues, StatusType } from '../../types'
import { useFeatures } from 'hooks'

interface Game extends CbGameCardProps {
  userId?: number
  status: StatusType
}

export interface GamesPageProps {
  activeFilter: string
  currentPage: number
  games: Game[]
  assumedUserName?: string
  isPending?: boolean
  numberOfRows: number
  totalEntries: number
  onFilterChange: (value: string) => void
  onGameClick?: (id: number) => void
  onPageChange: (pageNumber: number) => void
  onWinningsClick?: (event: SyntheticEvent) => void
}

export const GamesPage: FC<GamesPageProps> = ({
  activeFilter,
  currentPage,
  games,
  assumedUserName,
  isPending,
  numberOfRows,
  totalEntries,
  onFilterChange,
  onGameClick,
  onPageChange,
  onWinningsClick,
}) => {
  const { isMobile } = useContext(MPThemeContext)
  const { auth } = useContext(AuthContext)

  const ConditionalWrapper: any = ({ condition, wrapper, children }) =>
    condition ? wrapper(children) : children
  const hasGameCardUpgrade = useFeatures(Feature.GameCardsUpgrade)
  return (
    <Box>
      <CbColorBanner
        background="gradient.darkModeBanner"
        title="Game Center"
        rightElement={
          isMobile ? (
            <CbMobileFilterDateRange
              contentBackgroundColor="darkMode.500"
              defaultFilterValue={activeFilter}
              filterOptions={gamesFilterOptionValues}
              onFilterChange={onFilterChange}
              hasDateRange={false}
            />
          ) : (
            <>
              <Box width="180px">
                <CbSelect
                  color="white"
                  onChange={onFilterChange}
                  options={gamesFilterOptionValues}
                  value={activeFilter}
                  variant="cb-input-outlineWhite"
                />
              </Box>
            </>
          )
        }
      />
      <Box paddingX={{ base: 4, xl: 0 }}>
        <Flex justifyContent="space-evenly" marginTop={8}>
          <Box width={{ base: '100%' }}>
            <Heading
              as="h2"
              color="text.primaryWhite"
              fontSize="2xl"
              marginBottom={4}
              paddingX={{ base: 0, lg: 2, xl: 6 }}
            >
              {assumedUserName ? `${assumedUserName}'s games` : 'My Games'}
            </Heading>
            <ConditionalWrapper
              condition={isPending}
              wrapper={() => (
                <>
                  {hasGameCardUpgrade ? null : (
                    <>
                      <CbGameCardSkeletonOld key={0} marginBottom={4} />
                      <CbGameCardSkeletonOld key={1} marginBottom={4} />
                      <CbGameCardSkeletonOld key={2} marginBottom={4} />{' '}
                    </>
                  )}
                </>
              )}
            >
              {games?.length > 0 ? (
                hasGameCardUpgrade ? null : (
                  <>
                    {games.map(game => (
                      <CbGameCardOld
                        key={game.id}
                        id={game.id}
                        coverImageUrl={game.coverImageUrl}
                        name={game.name}
                        endDateTime={game.endDateTime}
                        startDateTime={game.startDateTime}
                        gameType={game.gameType}
                        goal={game.goal}
                        hasGameButton={game.status !== StatusType.Upcoming}
                        hasGameClick={game.status !== StatusType.Upcoming}
                        hasPlace={
                          game.gameType !== 'rpa_one_time' &&
                          game.gameType !== 'rpa_every_time'
                        }
                        isCompleted={game.status === StatusType.Completed}
                        isCurrentUser={game.userId === auth.id}
                        isUpcoming={game.status === StatusType.Upcoming}
                        metric={game.metric}
                        place={game.place}
                        prizeAmount={game.prizeAmount}
                        prizeType={game.prizeType}
                        rewards={game.rewards}
                        totalScore={game.totalScore}
                        userName={game.userName}
                        userImageUrl={game.userImageUrl}
                        unit={game.unit}
                        marginBottom={6}
                        onGameClick={onGameClick}
                        onWinningsClick={onWinningsClick}
                        numberOfWinners={game.rewards?.length}
                      />
                    ))}
                    <Box marginTop={8} marginBottom={12}>
                      <CbTablePagination
                        currentPage={currentPage}
                        isDarkMode
                        totalEntries={totalEntries}
                        numberOfRows={numberOfRows}
                        handlePageChange={onPageChange}
                        label={`${upFirst(activeFilter)} Games`}
                      />
                    </Box>
                  </>
                )
              ) : (
                <GamesEmptyState
                  activeFilter={activeFilter}
                  isMobile={isMobile}
                />
              )}
            </ConditionalWrapper>
          </Box>
        </Flex>
      </Box>
    </Box>
  )
}
